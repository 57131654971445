import React, { useEffect } from "react";
import Layout from "layout/opportunitiesLayout";
import { useState } from "react";
import { fetchWiserMatchesFilters, queryWiserMatches } from "../utils/requests";
import { Pagination, TopScrollBtn } from "components";
import { useAuth } from "contexts/AuthContext";
import { useLocation, useNavigate } from "react-router";
import useFilter from "../hooks/useFilter";
import { CardContainer } from "./style.wisermatches";
import OrganizationCard from "../components/OrganizationCard";
import { ContentContainer } from "../style";
import { numFormatter } from "services/Function/number";

function WiserMatches(props) {
    const SEARCHBAR_PLACEHOLDER = "Search by Organizations name or Keyword";
    const LIST_PAGE_RECORD_COUNT = 20;

    const { ID } = useLocation().state;
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const { currentUser, userLogOut } = useAuth();
    const [searchText, setSearchText] = useState("");

    const [data, setData] = useState({
        currentPage: 1,
        pageCount: 1,
        list: [],
    });

    const {
        filters,
        setFilterByKey,
        resetFilters,
        options,
        setOptionsWithResults,
    } = useFilter('Wiser Matches');

    useEffect(() => {
        queryFilteredData();
    }, [filters]);

    const queryFilteredData = (newPage = data.currentPage) => {
        const pageToQuery = newPage == data.currentPage ? 1 : newPage;
        setLoading(true);

        let filtersData = JSON.parse(JSON.stringify(filters));
        filtersData["search"] = searchText;

        const dataString = JSON.stringify({
        filters: filtersData,
        pageNum: pageToQuery,
        });
        // TODO: Un-comment when filters available from backend
    // fetchWiserMatchesFilters(dataString, currentUser, userLogOut)
    //     .then((results) => {
    //         // results['Organization_Type']=[['NIH', true]];
    //         console.log(results);
    //         setOptionsWithResults(results);
    //     })
    //     .catch((err) =>
    //         console.error(`Error Occurred during fetch: ${err.message}`)
    //     )
    //     .finally(() => setLoading(false));

    queryWiserMatches( ID, dataString, currentUser, userLogOut)
        .then((results) => {
            console.log(results.data);
            setData((prevData) => ({
            ...prevData,
            currentPage: pageToQuery,
            resultsCount: results.data.totalCount,
            pageCount: results.data.totalPageCount,
            // countOfFilteredProjects: results.totalCount,
            list: results.data.data.length > 0 
                ? results.data.data?.map((company) => {
                    return {
                        Company_Name: company.title,
                        Pharmaceuticals: ['-'],
                        Description: company.description,
                        Locations: company.location,
                        Employee_Size: company.employeeSize,
                        Total_Funding: numFormatter(company.totalFunding),
                        Count_of_Publications: 'N/A'
                    }
                })
                : results.data.data
            }));
        })
        .catch((err) =>
            console.error(`Error Occurred during fetch: ${err.message}`)
        )
        .finally(() => setLoading(false));
    };

    const paginate = (number) => {
        queryFilteredData(number);
        scrollToListContent();
    };

    const scrollToListContent = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        });
    };

    const handleSearchBarFocusCallback = (e) => {
        window.scrollTo(0, 0);
    };

    const handleBackBtnSubmit = () => {
        navigate(-1);
    };
    
    const FirstRecordOnPage = (data.currentPage - 1) * LIST_PAGE_RECORD_COUNT + 1;
    const LastRecordOnPage = Math.min(data.currentPage * LIST_PAGE_RECORD_COUNT, FirstRecordOnPage -1 + data.list.length);
    const TotalResults = data.resultsCount;

  return (
    <Layout
        title={'WISE-R Matches'}
        isLoaderDisplay={isLoading}
        filtersAlignCenter={true}
        footerRelative={true}
        searchbarPlaceholder={SEARCHBAR_PLACEHOLDER}
        handleSearchBarFocusCallback={handleSearchBarFocusCallback}
        handleSearchSubmit={queryFilteredData}
        handleSearchInput={(e) => setSearchText(e.target.value)}
        onBackClickCallback={handleBackBtnSubmit}
        filterOptions={options}
        filtersSelected={filters}
        handleClickFilters={setFilterByKey}
        handleResetFilters={resetFilters}
    >
      
        <ContentContainer
            margin={'0 auto'}>
            {data.pageCount > 0 && (
                <TopScrollBtn
                color="#2D6291"
                label={"To Top"}
                handleOnClick={scrollToListContent}
                />
            )}
            {
                data.list?.length > 0 && (
                    <div>
                        {`Showing ${FirstRecordOnPage} - ${LastRecordOnPage} of ${TotalResults} results`}
                    </div>
                )
            }
            <CardContainer>
                { data.list?.length > 0  
                    ? data.list?.map((item, index) => OrganizationCard(item, index)) 
                    : (
                        <h1 style={{color: '#827988', width: '100%', textAlign: 'center'}}>
                            No data Present at the moment
                        </h1>
                    ) }
            </CardContainer>
            <Pagination
            numberOfPages={data.pageCount}
            currentPage={data.currentPage}
            paginate={paginate}
            />
        </ContentContainer>
    </Layout>
  );
}

export default WiserMatches;
