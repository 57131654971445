import React from "react";
import { CheckboxWrapper } from "./style.fields";

const Checkbox = ({ label, fontSize, value, handleChange }) => {

    return (
        <CheckboxWrapper
            fontSize={fontSize}>
            <input 
                id={label} 
                type="checkbox" 
                checked={value} 
                onChange={handleChange} 
                style={{ margin: "8px 8px" }}
            />
            {label}
        </CheckboxWrapper>
        );
};

export default Checkbox;