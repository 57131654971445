import React from 'react'
import { FieldInputOption, RoundedInputField as StyledRoundedInputField } from './style.fields'
import { RoundedInputFieldContainer } from './style.fields'

function RoundedInputField(props) {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.keyDownCallback();
        }
    };

    return (
            <RoundedInputFieldContainer
                margin={props.margin}>
                    <StyledRoundedInputField 
                        name={props.placeholder}
                        type={props.type}
                        value={props.value}
                        className={props.className}
                        required={props.required}
                        ref={props.innerRef}
                        hasOptions={props.inputOption}
                        fontSize={props.fontSize}
                        onFocus={props.onFocusCallback}
                        onBlur={props.onBlurCallback}
                        onInput={props.onChangeCallback}
                        placeholder={props.placeholder}
                        onKeyDown={handleKeyDown}
                    />
                    {
                        props.inputOption
                        ? <FieldInputOption>{props.inputOption}</FieldInputOption>
                        : null
                    }
            </RoundedInputFieldContainer>
    )
}

export default RoundedInputField