import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  box-sizing: border-box;
  height: ${(props) => (props.height ? props.height : "100%")}; //optional

  overflow: hidden;
  border-radius: 4px;
  border-collapse: collapse;

  & td {
    border: 1px solid #d6e6e9;
  }
`;
export const TableHead = styled.thead`
  width: 100%;

  & tr {
    background-color: ${(props) => props.theme.colors.commonBlue};
    color: #fafaf9;
    text-align: ${(props) =>
      props.tAlign ? props.tAlign : "left"}; //make it optional
  }

  & th {
    width: fit-content;
  }

  & tr > th {
    padding: 0.55vh 0;
    font-size: ${(props) => props.theme.heading5.fontSize};
    font-weight: ${(props) => props.theme.heading5.fontWeight};
    white-space: nowrap;
  }

  & tr > th:first-of-type {
    padding: 0.55vh 4%;
    text-align: left;
    width: 70%;
  }
`;

export const TableBody = styled.tbody`
  & tr {
    text-align: center;
    color: #164050;
  }

  & tr > td:first-of-type {
    text-align: left;
  }

  & tr > td {
    padding: 0.55vh 4%;
    font-size: ${(props) => props.theme.bodyText.fontSize};
    font-weight: ${(props) => props.theme.bodyText.fontWeight};
  }

  & tr:nth-of-type(even) {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }
`;

export const TableDataDiv = styled.div`
  color: ${(props) => props.theme.colors.text.clickableText};
  /* color:#381650; */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
