import styled from "styled-components";
import { media } from "../../assets/static";

//---------------Component Pagination----------------

export const PaginationGrid = styled.div`
  margin: 10px 0px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PaginationGridUl = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: ${(props) => props.theme.colors.text.clickableText};

  font-size: ${(props) => props.theme.heading4.fontSize};
  font-weight: 500;
  font-family: "Libre Franklin", Arial, Helvetica, sans-serif;
  font-style: normal;

  .on {
    background-color: ${(props) => props.theme.colors.commonBlue};
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    color: ${(props) => props.theme.colors.text.whiteText};
  }
`;
export const PaginationGridLi = styled.li`
  list-style: none;
  cursor: pointer;
  /* width: 1.7vw; */
  padding: ${(props) => props.changePading};
  /* height: 3.4vh; */
  /* line-height: 4px; */
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px;
  border-radius: 50%;
`;

export const PaginationGridItem = styled.li`
  list-style: none;
  margin: 2px;
`;

export const PaginationGridButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  margin: 0px 5px;

  &:disabled {
    color: ${(props) => props.theme.colors.text.secondaryText};
    cursor: not-allowed;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "26vh")};
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 0.5vw;
`;
export const PaginationWrapperLineChart = styled.div`
  width: 100%;
  height: 120%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #584a9f;

  gap: 0.5vw;
`;

export const PageClick = styled.button`
  background: none;
  color: ${(props) => props.theme.colors.text.generalText};
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0px 3px;
  font-size: 12px;

  &:disabled {
    color: #c4c4c4;
    cursor: not-allowed;
  }

  ${media.monitor} {
    font-size: ${(props) => props.theme.heading3.fontSize};
  }

  ${media.large_desktop} {
    font-size: ${(props) => props.theme.heading4.fontSize};
  }

  ${media.laptop} {
    font-size: ${(props) => props.theme.heading6.fontSize};
  }
`;

export const NextPageClick = styled.button`
  &:disabled {
    color: #c4c4c4;
    cursor: not-allowed;
  }
`;
