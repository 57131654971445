import React from 'react'
import "./timeline.css";

function Timeline(props) {

    return (
        <section className="timeline">
            <div class="vLine"></div>
            <div class="timeline-line"></div>
            <div class="vLine" style={{ margin: '-12px 100%' }}></div>
            <ul>
                {
                    props.data.map((item, index) => {
                        return (
                            <li key={index}>
                                <span class="timeline-point"></span>
                                <p>{item.Date}</p>
                                <p>{item.Name}</p>
                                <p>{'( ' + item.Status + ' )'}</p>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    )

}

export default Timeline