import React from "react";
import { HeaderContainer, HeaderTitle } from "../style";
import { HeaderBackBtn, SearchBar, UserNameBtn } from "components";
import { HEADER_PLACEHOLDER } from 'services/Constants/constant';
import { useAuth } from "contexts/AuthContext";
import Logo from "assets/image/gaa-logo.png";

const Header = (props) => {

    const { currentUser } = useAuth();

    return (
        <HeaderContainer isHeaderFixed={props.isHeaderFixed}>
            { 
                props.onBackClickCallback
                    ? <HeaderBackBtn
                        OnClickCallback={props.onBackClickCallback}
                    />
                    : <HeaderTitle>
                        <img src={Logo} alt="Logo" style={{ height: "29px", marginRight: "5px" }}/>
                        ABL WISE-R
                    </HeaderTitle>
            }
            { 
                props.searchInputCallback
                    ? <SearchBar
                        placeholder={props.searchbarPlaceholder || HEADER_PLACEHOLDER}
                        handleSearchInput={props.searchInputCallback}
                        handleSearchFocusCallback={props.searchFocusCallback}
                        submitCallback={props.searchSubmitCallback}
                    />
                    : (
                        <HeaderTitle key="gaa-logo">
                            <img
                            src={Logo}
                            alt="Logo"
                            style={{ height: "29px", marginRight: "5px" }}
                            />
                            <div>ABL WISE-R<sup>SM</sup></div>
                        </HeaderTitle>
                    )
            }
            <UserNameBtn
                userName={currentUser.name}
            />
        </HeaderContainer>
    );
}

export default Header;