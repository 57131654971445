import { useState } from 'react';

export const defaultOpportunitiesFilters = {
    Published_Year: [], 
    Project_End_Year: [],
    Funded: [],
    Status: [ "Active" ], // show open opportunities by default
    Department: [],
    // Location: [],
    Recipient: [],
    // Recipient_Type: [],
    Diseases: [],
    Technology: [],
    // Notice_Type: [],
    Matches : []
};

export const defaultWiserMatchesFilters = {
    Organization_Type: [], 
    Technology: [],
    Diseases: [],
};

export const defaultOrganizationFilters = {
    // Status: [ "Active" ], // show open opportunities by default
    Diseases: [],
    // Location: [],
    // Phase: [],
    // Year_Started: [], 
    // Year_Completed: [],
    // Study_Type: [],
    // Site_Location: [],
    // Population_Age: [],
    // Sample_Size: [],
    // Published_Year: [], 
    // Project_End_Year: [],
    // Funded: [],
    // Department: [],
    // Recipient: [],
    Technology: [],
    // Notice_Type: [],
};

const getPagesDefaultFilterState = (page) => {
    switch (page) {
        case 'Opportunities': return defaultOpportunitiesFilters;
        case 'Wiser Matches': return defaultWiserMatchesFilters;
        case 'Organization': return defaultOrganizationFilters;
        default:
            return defaultOpportunitiesFilters;
    }
}

const useFilter = (page) => {

    const [ filters, setFilters ] = useState(getPagesDefaultFilterState(page));
    const [ options, setOptions ] = useState(getPagesDefaultFilterState(page));

    const setFilterByKey = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const setOptionsWithResults = (results) => {
        const newOptions = JSON.parse(JSON.stringify(options))
        Object.keys(newOptions).forEach((key) => {
            if (key in results) newOptions[key] = results[key];
        })
        setOptions(newOptions);
    }

    const resetFilters = (page) => {
        const emptyFilters = Object.assign({}, getPagesDefaultFilterState(page));
        Object.keys(emptyFilters).forEach((key) => {
            emptyFilters[key] = [];
        })
        setFilters(emptyFilters);
    }

    return {
        filters,
        setFilterByKey,
        resetFilters,
        options,
        setOptionsWithResults
    };
}

export default useFilter;
