import { getAPIParams } from "middleware/api.js";


export const getEncryptObj = async (data, project="CT") => {
  const [ MODE, PATH, PORT ] = getAPIParams(project);

  const encryptRes = await fetch(`${MODE}://${PATH}:${PORT}/v1/encrypt`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data }),
  });
  const encryptObj = await encryptRes.json();
  return encryptObj;
};
