import React from 'react'
import { ErrorContainer } from './style.fields'
import { MdError } from 'react-icons/md'

function Error(props) {
    
    return (
        <>
            {
                props.isVisible 
                && 
                <ErrorContainer
                    margin={props.margin}>
                    <MdError />
                    {props.error}
                </ErrorContainer>
            }
        </>
    )
}

export default Error