import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAPIParams } from "middleware/api.js";

const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password, shouldRememberMe }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        isPersistent: shouldRememberMe,
      }),
    }).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

const verifyTempPassword = createAsyncThunk(
  "auth/verifyTempPassword",
  async ({ email, tempPassword }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/login`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: tempPassword,
      }),
    }).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ email, name, department, newPassword }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/register`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        name: name,
        department: department,
        password: newPassword,
      }),
    }).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(
      `${MODE}://${PATH}:${PORT}/v1/user/forgotPassword`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    ).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

const setPassword = createAsyncThunk(
  "auth/setPassword",
  async ({ token, name, password }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(
      `${MODE}://${PATH}:${PORT}/v1/user/set-password`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          name: name,
          password: password,
        }),
      }
    ).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, currentPassword, newPassword }) => {
    const [MODE, PATH, PORT] = getAPIParams("CT");

    const response = await fetch(
      `${MODE}://${PATH}:${PORT}/v1/user/resetPassword`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          currentPassword: currentPassword,
          newPassword: newPassword,
        }),
      }
    ).then(async (rawResponse) => {
      const json = await rawResponse.json();
      if (rawResponse.ok) {
        return json;
      }
      throw json.message;
    });

    return response;
  }
);

export {
  userLogin,
  verifyTempPassword,
  registerUser,
  forgotPassword,
  setPassword,
  resetPassword,
};
