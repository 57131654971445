import { useState } from "react";
import { 
    FieldContainer, 
    FieldHeader, 
    FieldInput, 
    FieldInputContainer, 
    FieldInputOption, 
    FieldName, 
    FieldOptions 
} from "./style.fields";

const InputField = (props) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            props.keyDownCallback();
        }
    };

    return (
        <FieldContainer>
            {
                props.name
                ?   <FieldHeader>
                        <FieldName>
                            {props.name}
                        </FieldName>
                        {/* Field Options. eg. Forget-Password etc. */}
                        {
                            props.options
                            ? <FieldOptions>{props.options}</FieldOptions>
                            : null
                        }
                    </FieldHeader>
                : null
            }
            <FieldInputContainer>
                <FieldInput
                    hasInputOptions={props.inputOption}
                    ref={props.innerRef}
                    className={props.className}
                    type={props.type}
                    value={props.value}
                    required={props.required}
                    onFocus={props.onFocusCallback}
                    onBlur={props.onBlurCallback}
                    onInput={props.onChangeCallback}
                    placeholder={props.placeholder}
                    onKeyDown={handleKeyDown} 
                    inputOption={props.inputOption}
                >

                </FieldInput>
                {
                    props.inputOption
                    ? <FieldInputOption>{props.inputOption}</FieldInputOption>
                    : null
                }
            </FieldInputContainer>
        </FieldContainer>
    )
}

export default InputField;