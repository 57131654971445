import styled from "styled-components";

export const DownloadWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const DownloadOptions = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: ${props => props.theme.colors.text.clickableText_2};
`;

export const DownSelectArrow = styled.img`
    width: 0.66vw;
    height: 0.9vh;
`;

export const DownloadContainer = styled.div`
    display: none;
    ${'' /* flex-direction: column; */}
    position: absolute;
    right: 0;
    width: 11.3vw;
    height: 18.5vh;
    padding: 1.7vh 0.9vw;
    z-index: 10;
    background: #FAFAF9;
    border-radius: 24px;

    ${DownloadWrapper}:hover  & {
        display: flex;
        flex-direction: column;
    }
`;

export const DownloadListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar{
        display: none;
    }
`;

export const DownloadItemsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    padding: 1vh 0.6vw;
    border: 0.5px solid #C4C4C4;
    border-radius: 2px;
    &:hover {
        background: #D6E6E9;
    }
`;

export const DownloadItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const DownloadFileIcon = styled.img`
    width: 1vw;
    height: 2vh;
`;

export const DownloadItemName = styled.div`
    font-weight: ${props => props.theme.heading5.fontWeight};
    font-size: ${props => props.theme.heading5.fontSize};
    line-height: 1.2em;
    display: flex;
    align-items: center;
    color: #000000;
`;

export const DownloadItemSize = styled.div`
    font-weight: ${props => props.theme.bodyText.fontWeight};
    font-size: ${props => props.theme.heading5.fontSize};
    line-height: 1.2em;
    display: flex;
    align-items: center;
    color: #6C7073;
`;

export const DownloadButton = styled.img`
    width: 0.75vw;
    height: 1.5vh;
    padding-left: 20px;
    
    &:hover {
        cursor: pointer;
    }
`;

export const DownloadAll = styled.div`
    font-weight: ${props => props.theme.heading5.fontWeight};
    font-size: ${props => props.theme.heading5.fontSize};
    line-height: 2.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #549EB0;
`;