import { createSlice } from "@reduxjs/toolkit";
import { setPassword } from "../Requests/auth";

const setPasswordSlice = createSlice({
    name: "setPassword",
    initialState: {
        response: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(setPassword.pending, (state, action) => {
        state.isLoading = true;
        });
        builder.addCase(setPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        });
        builder.addCase(setPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        });
    },
});

export const authSetPasswordReducer = setPasswordSlice.reducer;