import styled from "styled-components";
import { theme } from "assets/static";
import { BoxedButton } from "components";

export const ListInfoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ShowResults = styled.div`
  display: flex;
  align-self: flex-start;

  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: ${(props) => props.theme.bodyText.fontSize};
  line-height: 1.2em;

  color: black;
`;

export const CardsContainer = styled.div`
  display: flex;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 71.4vw;
  height: fit-content; /*height: 33.4vh; */
  padding: 2.72vh 0;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 2px rgba(196, 196, 196, 0.2);
  border-radius: 24px;
`;

export const CardSummary = styled.div`
  max-width: 31%;
  min-width: 31%;
  padding: 0 2.15vw 0 2.73vw;
`;

export const CardDivider = styled.div`
  border: 1px solid #c4c4c4;
`;

export const CardDetails = styled.div`
  width: 61.36%;
  overflow: auto;
  padding: 0 2.3vw 0 2.62vw;
`;

export const CardHeaderRow = styled.div`
  display: flex;
  margin: 1vh 0 0 0;
  align-items: center;
  gap: 10px;
`;

export const CardSummaryStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 1vw;
  gap: 10px;

  width: fit-content;
  height: 3.4vh;

  background: ${(props) =>
    props.statusColor ? props.statusColor : props.theme.colors.lightGrey};
  border-radius: 4px;
`;

export const CardSummaryStatusText = styled.div`
  font-weight: ${(props) => props.theme.heading6.fontWeight};
  font-size: ${(props) => props.theme.heading6.fontSize};
  line-height: 1.2em;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "white")};
`;

export const CardTrialID = styled.div`
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: 0.666rem;
  line-height: 1.2em;
  color: #6c7073;
  margin-bottom: 1.2vh;
`;

export const CardSummaryTitle = styled.div`
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  font-size: 1rem;
  margin: 1.76vh 0 0.5vh 0;
  line-height: 1.2em;

  color: ${(props) => props.theme.colors.text.clickableText};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;

  &:hover {
    cursor: pointer;
  }
`;

export const CardSummaryDescription = styled.div`
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: ${(props) => props.theme.bodyText.fontSize};
  line-height: 1.2em;
  color: ${(props) => props.theme.colors.text.generalText};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
`;

export const CardDetailsHeaderRow = styled.div`
  display: flex;
  height: 3.4vh;
  align-items: center;
  flex-direction: row;
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: ${(props) => props.theme.bodyText.fontSize};
  margin: 1vh 0 0 0;

  color: ${(props) => props.theme.colors.text.generalText};
  justify-content: space-between;
`;

export const CardViewSource = styled.div`
  color: ${(props) => props.theme.colors.text.clickableText_2};
  text-align: center;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-style: normal;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.text.clickableText};
  }
`;

export const ClickableTextButton = styled.button`
  text-decoration: none;
  background: transparent;
  border: 0px;

  display: flex;
  align-items: center;
  width: fit-content;
  gap: 5px;

  :disabled {
    cursor: not-allowed;
  }
  & > a {
    text-decoration: none;
    box-sizing: border-box;
    width: fit-content;
    line-height: 3.5vh;
    color: #468aab;
    font-size: ${(props) => props.theme.heading4.fontSize};
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const DetailsContainerH = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.76vh 0 0 0;
`;

export const DetailWrapperH = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
`;

export const DetailHeading = styled.div`
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  font-size: ${(props) => props.theme.heading4.fontSize};
  line-height: 1.5em;
  white-space: pre;

  color: #171717;
`;

export const Detail = styled.div`
  font-size: ${(props) => props.theme.heading4.fontSize};
  line-height: 1.5em;

  color: #171717;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

//---------------------------------------------------------------

export const ContentContainer = styled.div`
  padding: 1.7vh 0 0;
  width: 100%;
  ${"" /* height: 100%; */}
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  color: rgba(27, 27, 27, 0.8);
  width: 71.86vw;
  margin: 0 auto;

  & > p {
    color: rgb(53, 119, 143);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  & > p:hover {
    color: rgba(150, 197, 216, 0.747);
  }
`;
