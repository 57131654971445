const validateTextField = (ref) => {
  let output = { isValid: true, error: "" };

  const text = ref.current.props?.value || ref.current.value;

  if (ref.current.props?.required || ref.current.required) {
    if (text.length == 0) {
      output = {
        isValid: false,
        error: "This field cannot be empty.",
      };
    } else if (text.length < 3) {
      output = {
        isValid: false,
        error: "This field must contain atleast 3 characters.",
      };
    }
  }

  return output;
};

const validateEmailField = (ref) => {
  let output = { isValid: true, error: "" };

  const email = ref.current.props?.value || ref.current.value;

  if (ref.current.props?.required || ref.current.required) {
    if (email.length == 0) {
      output = {
        isValid: false,
        error: "Email field cannot be empty.",
      };
      return output;
    }
  }

  if (email.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$") == null) {
    output = {
      isValid: false,
      error: "Email field must be of the form: characters@characters.domain",
    };
  }

  return output;
};

const validatePasswordField = (ref) => {
  let output = { isValid: true, error: "" };

  const pwd = ref.current.props?.value || ref.current.value;

  if (ref.current.props?.required || ref.current.required) {
    if (pwd.length == 0) {
      output = {
        isValid: false,
        error: "Password field cannot be empty.",
      };
      return output;
    }
  }

  return output;
};

const validateConfirmPasswordField = (ref1, ref2) => {
  let output = { isValid: true, error: "" };

  const pwd = ref1.current.props?.value || ref1.current.value;
  const cpwd = ref2.current.props?.value || ref2.current.value;

  if (pwd.length == 0 || cpwd.length == 0) {
    output = {
      isValid: false,
      error: "A Password field cannot be empty.",
    };
  } else if (pwd.length < 10 || cpwd.length < 10) {
    output = {
      isValid: false,
      error: "Passwords must contain atleast 10 digits.",
    };
  } else if (cpwd != pwd) {
    output = {
      isValid: false,
      error: "Passwords do not match.",
    };
  }

  return output;
};

export const validateField = (type, events) => {
  switch (type) {
    case "text":
      return validateTextField(events[0]);
    case "email":
      return validateEmailField(events[0]);
    case "password":
      return validatePasswordField(events[0]);
    case "confirmPassword":
      return validateConfirmPasswordField(events[0], events[1]);
  }
};
