import {
    WiserLogo,
    InfoAreaHeadingContainer,
    InfoAreaSubHeading,
    MInfoAreaForm,
    MInputFieldsContainer,
    LinkText,
} from "./styles";

import ProductLogo from "../../assets/image/gaa-logo.png";
import {
    Error,
    RoundedButton,
    RoundedInputField,
    ShowOrHideOption,
    TextButton,
} from "components";
import Layout from "../../layout/authLayout";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "../../services/Utils/Validator";
import { verifyTempPassword } from "../../store/Requests/auth";
import { ROUTE_LOGIN } from "services/Constants/constant";
import { handleGAAIconClick } from "./utils";

function SignUpForm(props) {
    const navigate = useNavigate();

    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const verifyButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_email = "email";
    const FORM_FIELD_TYPE_password = "password";
    const FORM_FIELD_PLACEHOLDER_email = "Company email ID";
    const FORM_FIELD_PLACEHOLDER_password = "Temporary password";
    const TEXT_verify = "Verify";
    const TEXT_signup = "Have an account? Sign in instead";
    const TEXT_FORM_subheading =
        "Please contact your company representative if you do not have a temporary password. If you do not know your representative, ";

    const { values, handleChange } = props;
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [focusedFields, setFocusedFields] = useState({
        email: false,
        password: false,
    });
    const [fieldsFocusedAtLeastOnce, setFieldsFocusedAtLeastOnce] = useState({
        email: false,
        password: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        areValid: true,
        errors: {
        email: {
            isValid: true,
            error: null,
        },
        password: {
            isValid: true,
            error: null,
        },
        others: {
            isValid: true,
            error: null,
        },
        },
    });

    const handleFocusChange = (label, value) => {
        setFocusedFields({
        ...focusedFields,
        [label]: value,
        });

        if (value && !fieldsFocusedAtLeastOnce.label) {
        setFieldsFocusedAtLeastOnce({
            ...fieldsFocusedAtLeastOnce,
            [label]: value,
        });
        }
    };

    const validateInputFields = (emailRef, pwdRef) => {
        let output = { areValid: true, errors: "" };

        const emailValidationResult = validateField(FORM_FIELD_TYPE_email, [
        emailRef,
        ]);
        const pwdValidationResult = validateField(FORM_FIELD_TYPE_password, [
        pwdRef,
        ]);

        if (emailValidationResult.isValid && pwdValidationResult.isValid) {
        output = {
            areValid: true,
            errors: {
            email: emailValidationResult,
            password: pwdValidationResult,
            },
        };
        } else {
        output = {
            areValid: false,
            errors: {
            email: emailValidationResult,
            password: pwdValidationResult,
            },
        };
        }

        return output;
    };

    useEffect(() => {
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(
                emailInputRef,
                passwordInputRef
            );
            if (areInputFieldsValid.areValid == false) {
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: {
                            ...errorMessages.errors.email,
                            isValid: areInputFieldsValid.errors.email.isValid,
                            error: areInputFieldsValid.errors.email.error,
                        },
                        password: {
                            ...errorMessages.errors.password,
                            isValid: areInputFieldsValid.errors.password.isValid,
                            error: areInputFieldsValid.errors.password.error,
                        },
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null,
                        },
                    },
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: {
                        ...errorMessages.errors.email,
                        isValid: true,
                        error: null,
                        },
                        password: {
                        ...errorMessages.errors.password,
                        isValid: true,
                        error: null,
                        },
                        others: {
                        ...errorMessages.errors.others,
                        isValid: true,
                        error: null,
                        },
                    },
                });
            }
        } else {
            didMountRef.current = true;
            verifyButtonRef.current.disabled = true;
        }
    }, [values]);

    const handleSubmit = () => {
        if (isSubmitting == false) return;

        setLoading(true);
        dispatch(
            verifyTempPassword({
                "email": values.email,
                "tempPassword": values.tempPassword
            })
        )
        .unwrap()
        .then((resJson) => {
            handleChange( "isVerified", true);
            props.nextStep();
        })
        .catch((error) => {
            setIsSubmitting(false);
            setErrorMessages({
            ...errorMessages,
            areValid: false,
            errors: {
                ...errorMessages.errors,
                others: {
                ...errorMessages.errors.others,
                isValid: false,
                error: error.statusText || error.message,
                },
            },
            });
        })
        .finally(() => setLoading(false));
    };

    return (
        <Layout
        isLoaderDisplay={loading}
        footerRelative={true}
        >
        <MInfoAreaForm>
            <InfoAreaHeadingContainer>
            <WiserLogo
                id="org-logo"
                src={ProductLogo}
                alt="GAA"
                onClick={handleGAAIconClick}
            />
            <div>Sign up</div>
            </InfoAreaHeadingContainer>
            <InfoAreaSubHeading>
                {TEXT_FORM_subheading}
                <LinkText
                    onClick={() => {}}
                >{'click here'}</LinkText>
            </InfoAreaSubHeading>

            <MInputFieldsContainer>
            <RoundedInputField
                placeholder={FORM_FIELD_PLACEHOLDER_email}
                type={FORM_FIELD_TYPE_email}
                required={true}
                value={values.email}
                innerRef={emailInputRef}
                className={errorMessages.errors.email.isValid ? "" : "invalid"}
                onChangeCallback={(e) => {
                    handleChange(FORM_FIELD_TYPE_email, e.target.value);
                }}
                onFocusCallback={() => {
                    handleFocusChange(FORM_FIELD_TYPE_email, true);
                }}
                onBlurCallback={() => {
                    handleFocusChange(FORM_FIELD_TYPE_email, false);
                }}
                keyDownCallback={handleSubmit}
            />
            <Error
                isVisible={
                !(
                    errorMessages.errors.email.isValid || focusedFields.email
                ) && fieldsFocusedAtLeastOnce.email
                }
                error={errorMessages.errors.email.error}
                margin={"0.5vh 1vw"}
            />

            <RoundedInputField
                placeholder={FORM_FIELD_PLACEHOLDER_password}
                type={FORM_FIELD_TYPE_password}
                required={true}
                value={values.tempPassword}
                innerRef={passwordInputRef}
                className={
                errorMessages.errors.password.isValid ? "" : "invalid"
                }
                onChangeCallback={(e) => {
                    handleChange("tempPassword", e.target.value);
                }}
                onFocusCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_password, true);
                }}
                onBlurCallback={() => {
                handleFocusChange(FORM_FIELD_TYPE_password, false);
                }}
                keyDownCallback={handleSubmit}
                inputOption={
                <div style={{ margin: "0 0 0 -4vw" }}>
                    <ShowOrHideOption fieldRef={passwordInputRef} />
                </div>
                }
            />
            <Error
                isVisible={
                !(
                    errorMessages.errors.password.isValid ||
                    focusedFields.password
                ) && fieldsFocusedAtLeastOnce.password
                }
                error={errorMessages.errors.password.error}
                margin={"0.5vh 1vw"}
            />
            <Error
                isVisible={!errorMessages.errors.others.isValid}
                error={errorMessages.errors.others.error}
                margin={"0.5vh 1vw"}
            />
            </MInputFieldsContainer>

            <RoundedButton
                height={"5.2vh"}
                textColor={"white"}
                backgroundColor={"#2D6291"}
                borderRadius={"14px"}
                disabled={isSubmitting ? false : true}
                innerRef={verifyButtonRef}
                handleClick={handleSubmit}
                >
                {TEXT_verify}
            </RoundedButton>
            <TextButton
                style={{ marginTop: "2.5vh" }}
                text={TEXT_signup}
                textColor={'#1E4161'}
                handleClick={() => navigate(ROUTE_LOGIN)}
            />
        </MInfoAreaForm>
            
        </Layout>
    );
}

export default SignUpForm;
