import styled from "styled-components";

export const TotalStatContainer = styled.div`
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  color: ${props => props.theme.colors.text.disabledText};
  font-size: ${(props) => props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};

  & > div:nth-child(2){
    font-size: ${(props) => props.theme.heading3.fontSize};
    font-weight: ${(props) => props.theme.heading3.fontWeight};
  }

  & > button{
    padding: 1vh 1vw;
    margin: 1vh 0 0 0;
    border: 1px solid rgba(106, 177, 218, 0.4);
    box-shadow: 3px 4px 4px 0px rgba(88, 158, 195, 0.05);
    background: ${props => props.theme.colors.generalWhite};
    font-size: ${(props) => props.theme.bodyText.fontSize};
  }
`;