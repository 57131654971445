import { TextButtonContainer } from "./style.button";

const TextButton = (props) => {

    return (
        <TextButtonContainer
            style={props.style}
            textColor={props.textColor}
            fontSize={props.fontSize}
            onClick={props.handleClick}>
                {props.text}
        </TextButtonContainer>
    );
}

export default TextButton;