import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword } from "../Requests/auth";

const forgotPasswordSlice = createSlice({
    name: "forgotPassword",
    initialState: {
        response: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(forgotPassword.pending, (state, action) => {
        state.isLoading = true;
        });
        builder.addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        });
    },
});

export const authForgotPasswordReducer = forgotPasswordSlice.reducer;