function getAPIParams(ENDPOINT) {
  switch (ENDPOINT) {
    case "ABL":
      return ["https", "abl-wiser.net", "443"];
    case "CT":
      return ["https", "ct-wiser.net", "443"];
    case "LOCAL":
      return ["http", "localhost", "8888"];
    default:
      return ["http", "localhost", "8888"];
  }
}

export { getAPIParams };
