import { RoundedButtonContainer } from "./style.button";

const RoundedButton = (props) => {
  return (
    <RoundedButtonContainer
      width={props.width}
      height={props.height}
      fontSize={props.fontSize}
      textColor={props.textColor}
      backgroundColor={props.backgroundColor}
      borderRadius={props.borderRadius}
      ref={props.innerRef}
      disabled={props.disabled}
      onClick={props.handleClick}
    >
      {props.children}
    </RoundedButtonContainer>
  );
};

export default RoundedButton;
