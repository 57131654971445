import { SearchBar } from "./style.searchBar";
import Input from "../ComponentUtils/input";
import { SearchBtn } from "../index";
import { BiSearch } from "react-icons/bi";

const SearchBarCont = (props) => {
  const handleSearchSubmit = () => {
    props.submitCallback(1);
  };

  return (
    <SearchBar width={props.width} height={props.height}>
      {props?.showSearchIcon == true && <BiSearch />}
      <Input
        value={props.searchText}
        onFocusCallback={props.handleSearchFocusCallback}
        onChangeCallback={props.handleSearchInput}
        placeholder={props.placeholder}
        keyDownCallback={props.submitCallback ? handleSearchSubmit : null}
      />
      {props.submitCallback && (
        <SearchBtn
          width={'16%'}
          fontSize={props.fontSize}
          handleSearchSubmit={handleSearchSubmit}
          margin={"0rem 0.2rem 0rem 0rem"}
        />
      )}
    </SearchBar>
  );
};

export default SearchBarCont;
