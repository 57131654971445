import {
  TableBody,
  TableHead,
  TableContainer,
  TableDataDiv,
} from "./style.table";

const TablesContainer = (props) => {
  return (
    <TableContainer height={props.tableHeight}>
      <TableHead tAlign={props.theadTextAlign}>
        <tr>
          {props.tableHeader &&
            props.tableHeader.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
        </tr>
      </TableHead>
      <TableBody>{getTableBody(props.tableData)}</TableBody>
    </TableContainer>
  );
};

const getTableBody = (data) => {
  // const endingIndex = this.state.governmentFunding * this.state.tableContentLicensed;
  // const startingIndex = endingIndex - this.state.tableContentLicensed;
  return (
    data &&
    data.length > 0 &&
    data.map((item, index) => {
      return (
        <tr key={index}>
          {item &&
            item.length > 0 &&
            item.map((td, id) => {
              return (
                <td key={id + td}>
                  <TableDataDiv>{td}</TableDataDiv>
                </td>
              );
            })}
        </tr>
      );
    })
  );
};

export default TablesContainer;
