import React from "react";
import downSelect from "../../assets/image/down_selected_blue.png";

import {
  DownloadAll,
  DownloadContainer,
  DownloadListContainer,
  DownloadOptions,
  DownloadWrapper,
  DownSelectArrow,
} from "./style.download";
import DownloadItem from "./DownloadItem";

const Download = (props) => {
  return (
    <DownloadWrapper>
      <DownloadOptions
        onClick={!props.isDownloadDropdown && props.handleDownloadClick}
      >
        <div>{"Download PDF"}</div>
        <DownSelectArrow src={downSelect} alt="Down Select Arrow" />
      </DownloadOptions>
      {props.isDownloadDropdown && (
        <DownloadContainer>
          <DownloadListContainer>
            {Object.entries(props.data).map((entry, index) => {
              const name = entry[1][0];
              const size = entry[1][1];
              const link = entry[1][2];

              return (
                <DownloadItem
                  key={index}
                  name={name}
                  size={size}
                  link={link}
                  handleOnClick={props.handleDownloadClick()}
                />
              );
            })}
          </DownloadListContainer>
          <DownloadAll>download all</DownloadAll>
        </DownloadContainer>
      )}
    </DownloadWrapper>
  );
};

export default Download;
