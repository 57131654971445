import React, { useState } from "react";
import { ChartContainer } from "./style.charts";
import { Line } from "react-chartjs-2";
import { processNumberString } from "../../services/Function/number";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Tooltip } from "components/Maps/style.map";
import { MdSquare } from "react-icons/md";
import { LEGEND_TEXT_MAX_LENGTH } from "services/Constants/constant";

const LineChart = ({
  data2,
  pageType,
  chartHeight,
  chartWidth,
  color,
  ifFill,
}) => {
  var chartData = [];
  var labelChart = [];
  const createLineData = (d) => {
    var dataLength = 0;
    Object.entries(data2).map(([key, val]) => {
      if (Object.values(val).length > 0 && dataLength < 3) {
        labelChart.push(key);
        chartData.push(val);
        dataLength++;
      }
    });
    var labelKeys = [];
    if (chartData.length > 0) labelKeys = Object.keys(chartData[0]);

    if (chartData) {
      var lineData = {
        labels: labelKeys,
        datasets: chartData.map((element, index) => {
          return {
            label: `${labelChart ? labelChart[index] : "Value"}`,
            data: Object.values(element),
            fill: ifFill && true,
            borderColor: color ? color[index] : "#164050",
            backgroundColor: color ? color[index] : "#164050",
            strokeColor: color ? color[index] : "#164050",
            lineTension: 0.4,
            lineRadius: 6,
            skipNull: true,
            pointStyle: "rect",
          };
        }),
      };
      return lineData;
    }
    return {};
  };

  const { height, width } = useWindowDimensions();
  const [tooltipData, setTooltipData] = useState({
    label: "",
    color: "",
    point: { x: 0, y: 0 },
  });

  const resizeFont = (height, width) => {
    if (height >= 2310 && width >= 3350) return 22;
    if (height >= 1210 && width >= 1800) return 15;
    if (height >= 920 && width >= 1380) return 10;
    if (height >= 720 && width >= 1024) return 8;
    if (height >= 620 && width >= 768) return 7;
    return 6;
  };

  const tooltipConfig = {
    backgroundColor: "rgba(250, 250, 249, 1)",
    titleColor: "#164050",
    bodyColor: "#164050",
    titleFont: { family: "Libre Franklin", weight: "normal" },
    bodyFont: { family: "Libre Franklin" },
    caretSize: 0,
    cornerRadius: 16,
    padding: 10,
    boxWidth: 16,
    boxHeight: 16,
    boxPadding: 5,
    borderColor: "rgba(0,0,0, 0.1)",
    borderWidth: 2,
    callbacks: {
      title: function (context) {
        let title = context[0].label;
        return "Year: " + title;
      },
      label: function (context) {
        let label = context.dataset.label || "";

        if (label) {
          label += ": ";
        }
        if (context.parsed.y !== null) {
          label += context.parsed.y.toLocaleString("en-US") + " trials";
        }
        return label;
      },
      labelColor: function (context) {
        return {
          backgroundColor: context.dataset.backgroundColor,
          borderWidth: 0,
          borderRadius: 4,
        };
      },
    },
  };

  return (
    <ChartContainer width={chartWidth} height={chartHeight}>
      <Line
        data={createLineData(data2)}
        options={{
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                switch (pageType) {
                  case "Funding":
                    const typeLabel =
                      data.datasets[tooltipItem["datasetIndex"]]["label"];
                    return `${typeLabel}: ${processNumberString(
                      tooltipItem["value"]
                    )}`;
                  case "Clinical Trials":
                    return `${processNumberString(
                      tooltipItem["value"]
                    )} trials`;
                  default:
                    return tooltipItem["value"];
                }
              },
            },
          },
          elements: {
            line: {
              borderWidth: 1,
              showLine: true,
            },
            point: {
              radius: 3,
              borderWidth: 0,
              hitRadius: 2,
            },
          },

          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                fontSize: resizeFont(height, width),
                boxWidth: 10, //resizeLegend(height, width),
                generateLabels: (chart) =>
                  chart.data.datasets.map((labelData, index) => ({
                    ...labelData,
                    text:
                      labelData.label.length > LEGEND_TEXT_MAX_LENGTH
                        ? labelData.label.substr(0, LEGEND_TEXT_MAX_LENGTH) +
                          "..."
                        : labelData.label,
                    fillStyle: labelData.backgroundColor,
                    datasetIndex: index,
                    hidden: !chart.getDatasetMeta(index).visible,
                    lineWidth: 0,
                    fontColor: "#666666",
                    useBorderRadius: true,
                    borderRadius: 2,
                  })),
              },
              onHover: function (event, legendItem, legend) {
                setTooltipData({
                  ...tooltipData,
                  label: legendItem.label,
                  color: legendItem.fillStyle,
                  point: { x: event.x, y: event.y + 15 },
                });
              },
              onLeave: function () {
                setTooltipData({
                  ...tooltipData,
                  label: "",
                  color: "",
                  point: { x: 0, y: 0 },
                });
              },
            },
            tooltip: tooltipConfig,
            datalabels: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
        }}
      />
      {tooltipData && (
        <Tooltip
          isVisible={tooltipData.label !== "undefined"}
          point={tooltipData.point}
        >
          <MdSquare size={"18px"} fill={tooltipData.color} />
          <span>{tooltipData.label}</span>
        </Tooltip>
      )}
    </ChartContainer>
  );
};

export default LineChart;
