import styled from "styled-components";

//------------HeaderBackBtn-----------------

export const HeaderBackBtnContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "4.4vw")};
  margin: ${(props) => (props.margin ? props.margin : "0")};

  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.heading4.fontSize};

  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0.4vw")};
  color: ${(props) => (props.color ? props.color : "#F0F0F0")};

  span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  span:first-child {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "1.2rem")};
  }

  :hover {
    cursor: pointer;
  }
`;

//-------------------Search Button-------------------

export const SubmitBtn = styled.div`
  width: ${(props) => (props.width ? props.width : "7vw")};
  min-width: fit-content;
  height: ${(props) => (props.height ? props.height : "100%")};

  padding: ${(props) => (props.padding ? props.padding : "8px 1vh 8px 1.2vh")};
  margin: ${(props) => (props.margin ? props.margin : "0px")};

  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.text.clickableText};

  display: flex;
  align-items: center;
  gap: ${(props) => (props.gap ? props.gap : "0.4vw")};
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  border-radius: 40px;

  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};

  cursor: pointer;

  span {
    height: 100%;
    display: flex;
    align-items: center;
  }

  span:first-child {
    font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  }
`;

//----------------UserNameBtn----------------

export const DownSelectArrow = styled.img`
  height: 0.6vw;
  padding: 1vh;
`;
export const UserNameWrapper = styled.div`
  display: block;
  position: relative;
`;

export const UserName = styled.div`
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.theme.heading4.fontSize};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.2vw;
  color: #ffffff;

  cursor: pointer;
`;

export const UserNameMenu = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 5px;
  position: absolute;
  right: 0;
  outline: none;
  z-index: 10;

  max-height: 17vh;
  overflow-y: auto;

  color: ${(props) => props.theme.colors.text.generalText};
  padding: 2vh 1vw;
  width: max-content;
  max-width: 14vw;
  height: auto;
  background: white;

  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  border-radius: 10px;
  cursor: pointer;
`;

export const UserNameMenuItem = styled.div``;

//-----------Info Btn------------------------
export const InfoBub = styled.div`
  display: flex;
  color: #437e8d;
`;

export const InfoBubTooltip = styled.div`
  --pointX: ${(props) => props.point.x};
  --pointY: ${(props) => props.point.y};

  position: absolute;
  display: ${(props) =>
    props.point.x === "0px" && props.point.y === "0px" ? "none" : "flex"};
  right: calc(100vw - var(--pointX));
  top: calc(var(--pointY) + 5px);

  width: max-content;
  max-width: 20vw;
  height: auto;
  outline: none;
  max-height: 17vh;
  padding: 0.73vh 1vw;
  color: ${(props) => props.theme.colors.text.generalText};
  background: ${(props) => props.theme.colors.generalWhite};

  font-size: ${(props) => props.theme.heading6.fontSize};
  font-weight: ${(props) => props.theme.heading6.fontWeight};
  border-radius: 16px;
  border: 0.4px solid #164050;
  box-shadow: 3px 12px 20px 0px rgba(35, 100, 124, 0.15);

  transition: 100ms transform;
  transform-origin: top center;
  z-index: 20;
`;

export const TopScrollBtnContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "fit-content")};
  height: ${(props) => (props.height ? props.height : "4.11vh")};
  margin: ${(props) => (props.margin ? props.margin : "0 3.67vw 0 0")};

  position: fixed;

  top: 86vh;
  left: 89vw;

  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.black};
  border-radius: 13px;

  color: #f0f0f0;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  line-height: 1.2em;
  /* text-align: center; */

  display: flex;
  visibility: ${(props) => (props.ifDisplay ? "visible" : "hidden")};
  justify-content: center;
  align-items: center;
  padding: 0.6vh 0.66vw;
  gap: ${(props) => (props.gap ? props.gap : "10px")};

  cursor: pointer;
  z-index: 30;
  white-space: nowrap;
`;

//----------------- Rounded Button -----------------
export const RoundedButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => (props.height ? props.height : "")};
  box-sizing: border-box;

  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};

  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.colors.text.clickableText};
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.lightBlue};

  padding: 0.5vh 1vw;
  border: ${(props) => (props.border ? props.border : "")};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "27.5px"};
  border-width: 0px;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.lightBlue};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.text.generalText};
    background-color: #dededd;
    cursor: auto;
  }
`;

export const TextButtonContainer = styled.div`
  text-align: center;
  line-height: normal;
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.colors.text.clickableText};

  cursor: pointer;
`;

//----------------- Boxed Button -----------------
export const BoxedButton = styled.button`
    box-sizing: border-box;
    background: none;
    padding: 8px;

    border: 1px solid ${props => props.theme.colors.text.clickableText};
    color: ${props => props.theme.colors.text.clickableText};
`;