import Header from "./Header/header";
import Footer from "./Footer/footer";
import Breadcrumb from "./Breadcrumb/breadcrumb";
import Loader from "./Loader/loader";
import { LINK } from "../services/Constants/constant";
import {
  LayoutProvider,
  BodyContainer,
  BreadcrumbWrapper,
  TextButton,
  TitleContainer,
} from "./style";

import FiltersRow from "./Filters/FiltersRow";

const Layout = (props) => {
  return (
    <LayoutProvider>
      <Loader isLoaderDisplay={props.isLoaderDisplay} />

      <Header
        isHeaderFixed={!props.isGraphSectionVisible}
        onBackClickCallback={props.onBackClickCallback}
        searchbarPlaceholder={props.searchbarPlaceholder}
        searchInputCallback={props.handleSearchInput}
        searchSubmitCallback={props.handleSearchSubmit}
        searchFocusCallback={props.handleSearchBarFocusCallback}
      />
      <BodyContainer marginTop={props.isGraphSectionVisible ? "" : "7.5vh"}>
        <BreadcrumbWrapper>
          <Breadcrumb />
          {
            props.showLastDataRefreshDate && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "4px",
                }}
              >
                <TextButton href={`${LINK}`}>Share Additional Data</TextButton>
                <div>Last updated on 02/13/2024</div>
              </div>
            )
          }
        </BreadcrumbWrapper>
        <TitleContainer>{props.title}</TitleContainer>
        {/* Plan is to define filter component in the Layout section and pass all the data from the page.
          In this project I am reducing logical component and making most of the component as UI component*/}
        {
          props.filtersSelected && (
            <FiltersRow
              filtersState={props.filtersSelected}
              filterOptions={props.filterOptions}
              handleClickFilters={props.handleClickFilters}
              handleResetFilters={props.handleResetFilters}
              isSingleSelect={props.singleSelect}
            />
          )
        }
        {props.children}
      </BodyContainer>
      <Footer relative={props.footerRelative} />
    </LayoutProvider>
  );
};

export default Layout;
