export const theme = {
  /* Base font size = 30px */

  heading0: {
    fontSize: "3.2rem",
    fontWeight: "500",
  },

  heading1: {
    fontSize: "1.8rem",
    fontWeight: "400",
  },

  heading2: {
    fontSize: "1.2rem",
    fontWeight: "300",
  },

  heading3: {
    fontSize: "1rem",
    fontWeight: "500",
  },

  heading4: {
    fontSize: "0.8rem",
    fontWeight: "300",
  },

  heading5: {
    fontSize: "0.533rem",
    fontWeight: "300",
  },

  bodyText: {
    fontSize: "0.666rem",
    fontWeight: "400",
  },

  /*----------- NOT IN FIGMA -----------*/
  heading6: {
    fontSize: "0.583rem",
    fontWeight: "300",
  },
  /* ---------------------------------- */

  colors: {
    background: "#F7F7F6",
    generalWhite: "#FAFAF9", // tile, filters, text, header, etc
    black: "#274A6A",
    darkGrey: "#6C7073", // secondary text
    lightGrey: "#C4C4C4", // unavailable button/ data
    gaaGreen: "#1D7A2F",
    darkestBlue: "#23647C", // footer, some clickable text
    commonBlue: "#2D6291", // filters, some text, buttons
    secondaryBlue: "#53BBDF", // Slider, Graphs,etc
    lightBlue: "#DBEAF3", // drop down highlight
    lightestBlue: "#EEFCFE", // text background (list view page)

    text: {
      generalText: "#274A6A",
      secondaryText: "#6C7073",
      clickableText: "#2D6291",
      clickableText_2: "#468AAB", // NOT PRESENT IN FIGMA-THEME
      disabledText: "#C4C4C4",
      whiteText: "#FAFAF9",
    },
  },
};
