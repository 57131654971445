import styled from "styled-components";

export const ActiveInfo = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  font-size: ${(props) => props.theme.heading4.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  color: ${(props) => props.theme.colors.text.generalText};

  & > div {
    margin: auto 0;

    & > span {
      font-size: ${(props) => props.theme.heading1.fontSize};
      font-weight: ${(props) => props.theme.heading1.fontWeight};

      padding: 5px;
    }
  }
`;

//----------------------------------------------------------------------

export const StatisticalTabsContainer = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  width: 100%;
  height: 6vh;
  gap: 10px;
`;

export const StatisticalTab = styled.div`
  display: flex;
  padding: 1.8vh 2vw;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border-radius: 0.8rem;
  background: ${(props) => props.theme.colors.generalWhite};
  box-shadow: 3px 12px 20px 0px rgba(88, 158, 195, 0.15);

  color: ${(props) => props.theme.colors.text.generalText};

  div:first-child {
    display: flex;
    font-size: ${(props) => props.theme.heading3.fontSize};
    font-weight: ${(props) => props.theme.heading3.fontWeight};
    line-height: 1.4;
  }

  div:last-child {
    display: flex;
    font-size: ${(props) => props.theme.heading4.fontSize};
    font-weight: ${(props) => props.theme.heading4.fontWeight};
    line-height: normal;
  }
`;

export const ShowResults = styled.div`
  display: flex;
  align-self: flex-start;
  ${"" /* margin-left: 14.3vw; */}

  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: 0.666rem;
  line-height: 1.2em;

  color: #6c7073;
`;

export const CardsContainer = styled.div`
  display: flex;
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 71.4vw;
  height: fit-content; /*height: 33.4vh; */
  padding: 2.72vh 0;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 2px rgba(196, 196, 196, 0.2);
  border-radius: 24px;
`;

export const CardSummary = styled.div`
  max-width: 31%;
  min-width: 31%;
  padding: 0 2.15vw 0 2.73vw;
`;

export const CardDivider = styled.div`
  border: 1px solid #c4c4c4;
`;

export const CardDetails = styled.div`
  width: 61.36%;
  overflow: auto;
  padding: 0 2.3vw 0 2.62vw;
`;

export const CardHeaderRow = styled.div`
  display: flex;
  margin: 1vh 0 0 0;
  align-items: center;
  gap: 10px;
`;

export const CardSummaryStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 1vw;
  gap: 10px;

  width: fit-content;
  height: 3.4vh;

  background: ${(props) =>
    props.statusColor ? props.statusColor : props.theme.colors.lightGrey};
  border-radius: 4px;
`;

export const CardSummaryStatusText = styled.div`
  font-weight: ${(props) => props.theme.heading6.fontWeight};
  font-size: ${(props) => props.theme.heading6.fontSize};
  line-height: 1.2em;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "white")};
`;

export const CardTrialID = styled.div`
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: 0.666rem;
  line-height: 1.2em;
  color: #6c7073;
`;

export const CardSummaryTitle = styled.div`
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  font-size: 1rem;
  margin: 1.76vh 0 1.2vh 0;
  line-height: 1.2em;
  cursor: pointer;

  color: ${(props) => props.theme.colors.text.clickableText};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const CardSummaryDescription = styled.div`
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: 0.666rem;
  line-height: 1.2em;
  color: #274a6a;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
`;

export const CardDetailsHeaderRow = styled.div`
  display: flex;
  height: 3.4vh;
  align-items: center;
  flex-direction: row;
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  font-size: 0.666rem;
  margin: 1vh 0 0 0;

  color: #274a6a;
  justify-content: space-between;
`;

export const CardViewSource = styled.div`
  color: ${(props) => props.theme.colors.text.clickableText_2};
  text-align: center;
  font-weight: ${(props) => props.theme.bodyText.fontSize};
  font-style: normal;
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.text.clickableText};
  }
`;

export const DetailsContainerV = styled.div`
  display: flex;
  height: 6.17vh;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  ${"" /* margin: 3.3vh 0; */}
  margin: 1.76vh 0 1.2vh 0;
`;

export const DetailsContainerH = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailWrapperV = styled.div`
  margin-right: 0.5vw;
  display: flex;
  flex-direction: column;
  font-weight: ${(props) => props.theme.heading4.fontWeight};
`;
export const DetailWrapperH = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
`;

export const DetailHeading = styled.div`
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  font-size: ${(props) => props.theme.heading4.fontSize};
  line-height: 1.75em;
  white-space: pre;

  color: #171717;
`;

export const Detail = styled.div`
  font-size: ${(props) => props.theme.heading4.fontSize};
  line-height: 1.75em;

  color: #171717;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

//---------------------------------------------------------------

export const ContentContainer = styled.div`
  padding: 1.7vh 0 0;
  width: 100%;
  ${"" /* height: 100%; */}
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  color: rgba(27, 27, 27, 0.8);
  width: 71.86vw;
  margin: 0 auto;

  & > p {
    color: rgb(53, 119, 143);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  & > p:hover {
    color: rgba(150, 197, 216, 0.747);
  }
`;

// --------------- Individual CT Page -----------------

export const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.72vw;
`;

export const HeaderRow = styled.div`
  display: flex;
  max-width: 76%;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const HeaderRowTrialTitle = styled.div`
  max-width: 78%;
  color: ${(props) => props.theme.colors.text.generalText};
  font-size: ${(props) => props.theme.heading3.fontSize};
  font-weight: ${(props) => props.theme.heading2.fontWeight};
  line-height: normal;
`;

export const HeaderRowLastUpdatedOn = styled.div`
  color: ${(props) => props.theme.colors.text.secondaryText};
  text-align: right;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  line-height: 1.75;
`;

export const DetailsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 1.5% 1.72vw 10vh;
  gap: 1.8vh;

  height: ${(props) => (props.height ? props.height : "auto")};
`;

export const DetailsCardRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 2vh;
`;

export const DetailsCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 2vh;
`;

export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: left;
  font-weight: 300;
  box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
  background-color: ${(props) => props.theme.colors.generalWhite};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "2.5vh 2vw")};
`;

export const CardTitle = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.text.generalText};
  font-size: ${(props) => props.theme.heading3.fontSize};
  font-weight: ${(props) => props.theme.heading2.fontWeight};
  line-height: normal;
  margin-bottom: 1vh;
`;

export const CardBody = styled.div`
  color: black;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};
  font-style: normal;
  line-height: normal;
`;

export const RowWrapper = styled.div`
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.heading4.fontWeight};

  line-height: 1.75em;
  color: #171717;
`;

export const RowProperty = styled.span`
  font-weight: ${(props) => props.theme.heading3.fontWeight};
  white-space: pre;
`;

export const RowValue = styled.span``;
