import styled from "styled-components";

export const Tooltip = styled.div`
    position: absolute;
    display: ${
        props => props.isVisible ? 
            ( props => props.point.x === 0 && props.point.y === 0 ? 'none': 'flex')
            : 'none' 
    };
    
    min-width: 5.3vw;
    max-width: 15vw;
    min-height: 2.5vh;
    max-height: 5vh;
    left: ${props => props.point.x + 'px'};
    top: ${props => props.point.y + 'px'};
    gap: 5px;

    background: #FFFFFF;
    box-shadow: 3px 12px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0.6vh 0.7vw;
    margin-left: 0.4vw; 

    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.heading6.fontWeight};
    align-items: center;
    color: #000000;
`;

export const Legend = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    ${'' /* position: absolute; */}
    ${'' /* width: 9vw;  */}
    ${'' /* height: 11vh;  */}
    ${'' /* top: 50%;  */}
    ${'' /* left: 28%; */}
    padding: 0 0.5vw;
    margin: auto;
    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.heading6.fontWeight};
`;