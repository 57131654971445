import styled from "styled-components";

export const GraphGridWrapper = styled.main`
    box-sizing: border-box;
    display: grid;
    flex-grow: 1;
    grid-template-columns: ${props=>props.gridTempCol? props.gridTempCol : 'repeat(21, 1fr)'};
    grid-template-rows: ${props=>props.gridTempRow? props.gridTempRow : 'repeat(16, 3.97%)'};
    margin: 1.5% 1.72vw 0;
    gap: 1.8vh;

    height: ${props=>props.height?props.height:'75vh'};
`