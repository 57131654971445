export const getStatusColor = (status) => {
  switch (status) {
    case "Active":
      return ["#C6DAEB", "#000000"];
    case "Inactive":
      return ["#DBEAF3", "#6C7073"];
    case "Unavailable":
      return ["#C4C4C4", "#FFFFFF"];
    default:
      return ["#C4C4C4", "#FFFFFF"];
  }
};

const colorPalette = [
  "#A6C9D7",
  "#659BB6",
  "#468AAB",
  "#33708D",
  "#255166",
  "#255166",
  "#255166",
  "#255166",
];

export const createSunBurstChartData = (data, chData, chId) => {
  var dataToBe = [
    {
      id: "root",
      // name: chId,
      fill: "#fff",
      label: {
        fontColor: "#23647C",
        // fontWeight: "bold",
      },
    },
  ];

  Object.entries(data).map((item, index) => {
    const rootChild = {
      id: item[0],
      parent: "root",
      name: item[0],
      fill: colorPalette[index],
      value: item[1],
    };

    dataToBe = [...dataToBe, rootChild];
  });

  if (chData) {
    dataToBe = [...dataToBe, ...chData];
  }

  return dataToBe;
};
