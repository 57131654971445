import { GraphGridWrapper } from './style.componentUtil';

const GraphGrid = (props) => {
    return (
        <GraphGridWrapper
            gridTempCol={props.gridTempCol}
            gridTempRow={props.gridTempRow}
            height = {props.height}
        >
            {props.children}
        </GraphGridWrapper>
    );
}

export default GraphGrid;