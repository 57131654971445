import { BiSearch } from "react-icons/bi";
import { SubmitBtn } from "./style.button";

const SearchBar = (props) => {
    return (
        <SubmitBtn
            onClick={props.handleSearchSubmit}
            width={props.width}
            height={props.height}
            margin={props.margin}
            padding={props.padding}
            gap={props.gap}
            color={props.color}
            fontSize={props.fontSize}
        >
            <span><BiSearch/></span>
            <span>Search</span>
        </SubmitBtn>
    );
}

export default SearchBar;