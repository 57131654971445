import React from 'react';
import { LearnMoreButton } from "./style.components";
import { useNavigate } from 'react-router';

export default function LearnMore({ item }) {
    const navigate = useNavigate();

    return (
        <LearnMoreButton
            onClick={() => navigate(`${item["Company_Name"]}`, { state: item })}
        >
            Learn More
        </LearnMoreButton>
    )
}