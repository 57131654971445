import React, { Component } from "react";
import { FiltersContainer } from "./style";
import { ResetFilter } from "./style";
import Filter from "./Filter";
import ErrorWrapper from "middleware/errorWrapper";

const FiltersRow = (props) => {
  let showReset = false;  

  return (
    <ErrorWrapper>
      <div 
        style={{ 
            position: props.alignCenter ? 'absolute' : 'relative', 
            width: props.alignCenter && '-webkit-fill-available', 
            height: "3.5vh", 
            zIndex: "50" 
        }}>
        <FiltersContainer alignCenter={props.alignCenter}>
          {props.filterOptions &&
            Object.entries(props.filterOptions).map((item) => {
              let filterType = false;
              if (
                props.isSingleSelect &&
                props.isSingleSelect[0].toLowerCase() == "all"
              ) {
                filterType = true;
              } else if (
                props.isSingleSelect &&
                props.isSingleSelect.includes(item[0]) == true
              ) {
                filterType = true;
              }
              if (
                item[0] != "Disease" &&
                Array.isArray(props.filtersState[item[0]]) &&
                props.filtersState[item[0]].length > 0 &&
                showReset == false
              ) {
                showReset = true;
              }
              if (item[1].length > 0) {
                return (
                  <Filter
                    key={item[0]}
                    filterLabel={item[0]}
                    loading={false}
                    filterOption={item[1]}
                    filtersSelected={props.filtersState}
                    handleClickFilters={props.handleClickFilters}
                    isSingleSelect={filterType}
                  />
                );
              }
            })}
          {showReset && (
            <ResetFilter onClick={props.handleResetFilters}>
              Reset Filters
            </ResetFilter>
          )}
        </FiltersContainer>
      </div>
    </ErrorWrapper>
  );
};

export default FiltersRow;
