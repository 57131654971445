import { createSlice } from "@reduxjs/toolkit";
import { resetPassword } from "../Requests/auth";

const resetPasswordSlice = createSlice({
    name: "resetPassword",
    initialState: {
        response: [],
        isLoading: false,
        error: null,
    },
    extraReducers(builder) {
        builder.addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        });
    },
});

export const authResetPasswordReducer = resetPasswordSlice.reducer;