import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { PaginationGrid, PaginationGridUl, PaginationGridLi, PaginationGridItem, PaginationGridButton } from "./style.pagination";

const Pagination = ({ numberOfPages, paginate, currentPage }) => {

    const paginateArray = [];

    let ellipsisLeft = false;
    let ellipsisRight = false;

    if (numberOfPages <= 2) {
        for (let i = 1; i <= numberOfPages; i++) {
            paginateArray.push({
                id: i,
                ellipsis: false
            })
        }
    } else {
        for (let i = 1; i <= numberOfPages; i++) {
            if (currentPage === 1 || currentPage === numberOfPages || currentPage < 3 || currentPage > numberOfPages - 3) {
                if (i === currentPage) {
                    paginateArray.push({
                        id: i,
                        ellipsis: false
                    })
                } else if (i < 4 || i > numberOfPages - 2 || i === currentPage - 1 || i === currentPage + 1) {
                    paginateArray.push({
                        id: i,
                        ellipsis: false
                    })
                } else if (i > 1 && i < currentPage && !ellipsisLeft) {
                    paginateArray.push({
                        id: i,
                        ellipsis: true
                    })
                    ellipsisLeft = true;

                } else if (i < numberOfPages && i > currentPage && !ellipsisRight) {
                    paginateArray.push({
                        id: i,
                        ellipsis: true
                    })
                    ellipsisRight = true;
                }
            } else {
                if (i === currentPage) {
                    paginateArray.push({
                        id: i,
                        ellipsis: false
                    })
                } else if (i < 2 || i > numberOfPages - 1 || i === currentPage - 1 || i === currentPage + 1) {
                    paginateArray.push({
                        id: i,
                        ellipsis: false
                    })
                } else if (i > 1 && i < currentPage && !ellipsisLeft) {
                    paginateArray.push({
                        id: i,
                        ellipsis: true
                    })
                    ellipsisLeft = true;

                } else if (i < numberOfPages && i > currentPage && !ellipsisRight) {
                    paginateArray.push({
                        id: i,
                        ellipsis: true
                    })
                    ellipsisRight = true;
                }
            }
        }
    }


    return (
        paginateArray.length > 1 ?
            <PaginationGrid>
                <PaginationGridUl>
                    {/* <PaginationGridButton onClick={() => { paginate(1) }} disabled={currentPage === 1}> <FaBackward /> </PaginationGridButton> */}
                    <PaginationGridButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>  <BsFillCaretLeftFill /> </PaginationGridButton>
                    {paginateArray.map(item => {
                        if (!item.ellipsis) {
                            return <PaginationGridLi
                                key={item.id}
                                className={`${currentPage == item.id && 'on'}`}
                                onClick={() => { paginate(item.id) }}
                                changePading={() => {
                                    if (item.id < 10) {
                                        return '4px 8px'
                                    } else if (item.id >= 10 && item.id < 100)
                                    {
                                        return '6px 8px'
                                    } else if (item.id >= 100 && item.id <= 999) {
                                        return '9px 6px'
                                    } else {
                                        return '13px 6px'
                                    }
                                }}
                            >
                                {item.id}
                            </PaginationGridLi>
                        } else {
                            return <PaginationGridItem key={item.id}>
                                <span>&hellip;&hellip;&hellip;</span>
                            </PaginationGridItem>
                        }
                    })}
                    <PaginationGridButton onClick={() => paginate(currentPage + 1)} disabled={currentPage === numberOfPages}> <BsFillCaretRightFill /> </PaginationGridButton>
                    {/* <PaginationGridButton onClick={() => { paginate(numberOfPages) }} disabled={currentPage === numberOfPages}> <FaForward /> </PaginationGridButton> */}
                </PaginationGridUl>
            </PaginationGrid>
            : (<div style={{ marginBottom: '60px'}}></div>)
    );


}

export default Pagination;