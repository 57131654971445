//------------Layout----------------------
export const HEADER_PLACEHOLDER = `Search by Disease Name or Keyword`;
export const LINK = `mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data`;
export const LINK_M = `mailto:hansilla.alaigh@globalactionalliance.net`;
export const LINK_GAA = `https://www.gaawiser.com`;
export const LINK_GAA_about = LINK_GAA + `/about`;

//------------ Routes ----------------------
export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_PRE_REGISTER = "/pre-register";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_SET_PASSWORD = (token) => {
  return `/set-password/${token}`;
};
export const ROUTE_DISEASE_SELECTION = "/disease-selection";
export const ROUTE_CLINICAL_TRIALS = (disease) => {
  return `/disease-selection/${disease}`;
};
export const ROUTE_INDIVIDUAL_TRIAL = (disease, trialId) => {
  return `/disease-selection/${disease}/${trialId}`;
};
export const ROUTE_OPPORTUNITIES = "/opportunities";
export const ROUTE_OPPORTUNITY_DETAILS = (opportunityId) => {
  return `/opportunities/${opportunityId}`;
};

export const ROUTE_gaa_logo = "/assets/gaa-logo";

//------------Disease Selection----------------
export const PROJECT = "ABL";

//------------Clinical Trails----------------
export const CLINICAL_TRAIL_FILTER_TAG = "CLINICAL_TRIALS";

export const FILTER_DELAY = 0; // in millie-seconds, times 1000 to convert to seconds
export const LEGEND_TEXT_MAX_LENGTH = 20;

// export const sunburnChartColor = [
//   "#A6C9D7",
//   "#659BB6",
//   "#468AAB",
//   "#33708D",
//   "#255166",
//   "#255166",
//   "#255166",
// ];

export const byPhaseColorArr = [
  "#A6C9D7",
  "#659BB6",
  "#468AAB",
  "#33708D",
  "#255166",
];
export const byAgeColorArr = [
  "#A8B97C",
  "#B4C788",
  "#D9E8B2",
  "#E7F0D0",
  "#E1F2DD",
];
export const bySampleSizeColorArr = [
  "#437E8D",
  "#73B8C9",
  "#84BAC5",
  "#B5E5F0",
  "#CAEEF8",
  "#D8F0F5",
];
export const byYearColorArr = [
  "#164050",
  "#659BB6",
  "#468AAB",
  "#33708D",
  "#255166",
];
export const bySponsorTypeStackedColorArr = [
  "#194D61",
  "#335f70",
  "#4c717f",
  "#66838e",
];

export const HORIZONTAL_BAR_DEFAULT_COLOR = [
  "#366672",
  "#437E8D",
  "#549EB0",
  "#70A9A3",
  "#82B09A",
  "#A1C4B3",
];

export const COLORS_MAP_LEGEND_1 = ['#A6C9D7', '#659BB6', '#33708D', '#255166'];
export const DEFAULT_FALLBACK =
  "There was some error. Please try again later!!";

export const tempDownloadData = {
  0: ["title here1. doc", "171 bytes", "Download Link"],
  1: ["title here2. doc", "172 bytes", "Download Link"],
  2: ["title here3. doc", "173 bytes", "Download Link"],
  3: ["title here4. doc", "174 bytes", "Download Link"],
  4: ["title here5. doc", "175 bytes", "Download Link"],
  5: ["title here6. doc", "176 bytes", "Download Link"],
};

export const ctStatusColor = {
  Active: ["#C6DAEB", "#000000"],
  Inactive: ["#DBEAF3", "#6C7073"],
  Unavailable: ["#C4C4C4", "#FFFFFF"],
};

export const opportunityStatusColor = {
  Active: ["#2D6291", "#FFFFFF"],
  Inactive: ["#C4C4C4", "#274A6A"],
  Unavailable: ["#C4C4C4", "#FFFFFF"],
};

export const opportunityFundingColor = {
  FUNDED: ["#2D6291", "#FFFFFF"],
  "NOT FUNDED": ["#C4C4C4", "#274A6A"],
  Unavailable: ["#C4C4C4", "#FFFFFF"],
};