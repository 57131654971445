import styled from "styled-components";

export const SearchBar = styled.div`
  width: ${(props) => (props.width ? props.width : "28vw")};
  height: ${(props) => (props.height ? props.height : "3vh")};
  min-width: 400px;
  min-height: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  padding: 5px 2px 5px 1.5vw;
  box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.1);
  border-radius: 76.5201px;

  background-color: ${(props) => props.theme.colors.generalWhite};

  color: ${(props) => props.theme.colors.text.clickableText};

  & > input {
    width: 100%;
    background: transparent;
    color: ${(props) => props.theme.colors.text.clickableText};
    border: none;
    font-size: ${(props) => props.theme.heading4.fontSize};
    font-weight: ${(props) => props.theme.heading4.fontWeight};

    ::placeholder {
      color: ${(props) => props.theme.colors.text.clickableText};
    }

    &:focus::placeholder {
      opacity: 0.5;
    }
  }

  & > input:focus {
    outline: none;
  }
`;
