import React, { useEffect, useRef, useState } from 'react'
import { 
    WiserLogo, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
} from "./styles";
import ProductLogo from "../../assets/image/gaa-logo.png";
import {  
    Error, 
    RoundedButton, 
    RoundedInputField, 
    ShowOrHideOption, 
} from "components";
import Layout from '../../layout/authLayout'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { validateField } from 'services/Utils/Validator';
import { 
    MInfoAreaForm, 
    MInputFieldsContainer 
} from './styles/style.Auth.Mobile';
import { registerUser } from 'store/Requests/auth';
import { ROUTE_LOGIN } from 'services/Constants/constant';
import { handleGAAIconClick } from './utils';

function CreateAccountForm(props) {
    
    const navigate = useNavigate();

    const nameInputRef = useRef(null);
    const deptInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const confirmPasswordInputRef = useRef(null);
    const createAccountButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const FORM_FIELD_TYPE_text = "text";
    const FORM_FIELD_TYPE_password = "password";
    const FORM_FIELD_TYPE_confirm_password = "confirmPassword";
    const FORM_FIELD_PLACEHOLDER_name = "Name";
    const FORM_FIELD_PLACEHOLDER_department = "Department";
    const FORM_FIELD_PLACEHOLDER_password = "Password";
    const FORM_FIELD_PLACEHOLDER_confirm_password = "Confirm password";
    const TEXT_create_account = 'Create account';
    const TEXT_FORM_subheading = "Please create your personal account and choose a new password.";

    const { values, handleChange } = props;
    const [ confirmPassword, setConfirmPassword ] = useState('');
    
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        name: false,
        department: false,
        password: false,
        confirmPassword: false
    });
    const [ fieldsFocusedAtLeastOnce, setFieldsFocusedAtLeastOnce ] = useState({
        name: false,
        department: false,
        password: false,
        confirmPassword: false
    });

    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "name": { 
                isValid: true, 
                error: null
            },
            "department": { 
                isValid: true, 
                error: null
            },
            "confirmPassword": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
        
        if(value && !fieldsFocusedAtLeastOnce.label){
            setFieldsFocusedAtLeastOnce({
                ...fieldsFocusedAtLeastOnce,
                [label]: value
            })
        }
    }

    const validateInputFields = (nameRef, deptRef, pwdRef, cpwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const nameValidationResult = validateField(FORM_FIELD_TYPE_text, [nameRef]);
        const deptValidationResult = validateField(FORM_FIELD_TYPE_text, [ deptRef ]);
        const cpwdValidationResult = validateField(FORM_FIELD_TYPE_confirm_password, [pwdRef, cpwdRef ]);

        if( nameValidationResult.isValid && deptValidationResult.isValid && cpwdValidationResult.isValid ){
            output = { 
                areValid: true, 
                errors: { 
                    "name": nameValidationResult,
                    "department": deptValidationResult, 
                    "confirmPassword": cpwdValidationResult 
                }}
        }else{
            output = { 
                areValid: false, 
                errors: { 
                    "name": nameValidationResult,
                    "department": deptValidationResult, 
                    "confirmPassword": cpwdValidationResult 
                }}
        }

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current){

            const areInputFieldsValid = validateInputFields(
                                                            nameInputRef, 
                                                            deptInputRef, 
                                                            passwordInputRef, 
                                                            confirmPasswordInputRef
                                                            );

            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: false,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: areInputFieldsValid.errors.name.isValid, 
                            error: areInputFieldsValid.errors.name.error},
                        department: { 
                            ...errorMessages.errors.department, 
                            isValid: areInputFieldsValid.errors.department.isValid, 
                            error: areInputFieldsValid.errors.department.error},    
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: areInputFieldsValid.errors.confirmPassword.isValid, 
                            error: areInputFieldsValid.errors.confirmPassword.error},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });

            }else{
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        name: { 
                            ...errorMessages.errors.name, 
                            isValid: true, 
                            error: null},
                            department: { 
                            ...errorMessages.errors.department, 
                            isValid: true, 
                            error: null},    
                        confirmPassword: { 
                            ...errorMessages.errors.confirmPassword, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others, 
                            isValid: true, 
                            error: null
                        }
                    }
                });
            }

        }else{
            didMountRef.current = true;
            createAccountButtonRef.current.disabled = true;
        }
    },[values, confirmPassword]);

    const createAccount = () => {

        if(isSubmitting == false)
            return;

        setLoading(true);
        dispatch(
            registerUser({
                "email": values.email,
                "name": values.name,
                "department": values.department,
                "newPassword": values.newPassword
            })
        )
        .unwrap()
        .then(resJson => {
            navigate(ROUTE_LOGIN);
        })
        .catch(error => {
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }

    return (
        <Layout
            isLoaderDisplay={loading}
            footerRelative={true}
        >
            <MInfoAreaForm>
                <InfoAreaHeadingContainer>
                    <WiserLogo
                        id="org-logo"
                        src={ProductLogo}
                        alt="GAA"
                        onClick={handleGAAIconClick}
                    />
                    <div>Sign up</div>
                </InfoAreaHeadingContainer>
                <InfoAreaSubHeading>{TEXT_FORM_subheading}</InfoAreaSubHeading>

                <MInputFieldsContainer>
                    <RoundedInputField 
                        placeholder={FORM_FIELD_PLACEHOLDER_name}
                        type={FORM_FIELD_TYPE_text}
                        value={values.name}
                        innerRef={nameInputRef}
                        required={true}
                        className={ errorMessages.errors.name.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("name", e.target.value)}}
                        onFocusCallback={(e) => {handleFocusChange("name", true)}}
                        onBlurCallback={(e) => {handleFocusChange("name", false)}}
                        keyDownCallback={createAccount}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.name.isValid || focusedFields.name) && fieldsFocusedAtLeastOnce.name }
                        error={ errorMessages.errors.name.error }
                        margin={'0.5vh 1vw'}
                    />
                    
                    <RoundedInputField 
                        placeholder={FORM_FIELD_PLACEHOLDER_department}
                        type={FORM_FIELD_TYPE_text}
                        required={true}
                        value={values.department}
                        innerRef={deptInputRef}
                        className={ errorMessages.errors.department.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("department", e.target.value)}}
                        onFocusCallback={() => {handleFocusChange("department", true)}}
                        onBlurCallback={() => {handleFocusChange("department", false)}}
                        keyDownCallback={createAccount}
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.department.isValid || focusedFields.department) && fieldsFocusedAtLeastOnce.department }
                        error={ errorMessages.errors.department.error }
                        margin={'0.5vh 1vw'}
                    />
                    
                    <RoundedInputField 
                        placeholder={FORM_FIELD_PLACEHOLDER_password}
                        type={FORM_FIELD_TYPE_password}
                        value={values.newPassword}
                        innerRef={ passwordInputRef }
                        required={true}
                        className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {handleChange("newPassword", e.target.value)}}
                        onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, true)}}
                        onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_password, false)}}
                        keyDownCallback={createAccount}
                        inputOption={ <div style={{ margin: '0 0 0 -4vw' }}><ShowOrHideOption fieldRef={passwordInputRef} /></div> }
                    />
                    <RoundedInputField 
                        placeholder={FORM_FIELD_PLACEHOLDER_confirm_password}
                        type={FORM_FIELD_TYPE_password}
                        value={confirmPassword}
                        innerRef={ confirmPasswordInputRef }
                        required={true}
                        className={ errorMessages.errors.confirmPassword.isValid ? "" : "invalid" }
                        onChangeCallback={(e) => {setConfirmPassword(e.target.value)}}
                        onFocusCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, true)}}
                        onBlurCallback={(e) => {handleFocusChange(FORM_FIELD_TYPE_confirm_password, false)}}
                        keyDownCallback={createAccount}
                        inputOption={ <div style={{ margin: '0 0 0 -4vw' }}><ShowOrHideOption fieldRef={confirmPasswordInputRef} /></div> }
                    />
                    <Error 
                        isVisible={ !(errorMessages.errors.confirmPassword.isValid || focusedFields.password || focusedFields.confirmPassword) && fieldsFocusedAtLeastOnce.password && fieldsFocusedAtLeastOnce.confirmPassword }
                        error={ errorMessages.errors.confirmPassword.error }
                        margin={'0.5vh 1vw'}
                    />
                    <Error 
                        isVisible={ !errorMessages.errors.others.isValid }
                        error={ errorMessages.errors.others.error }
                        margin={'0.5vh 1vw'}
                    />

                </MInputFieldsContainer>

                <RoundedButton 
                    height={'5.2vh'}
                    textColor={"white"}
                    backgroundColor={'#2D6291'}
                    borderRadius={'14px'}
                    disabled={ errorMessages.areValid ? false : true }
                    innerRef={createAccountButtonRef}
                    handleClick={createAccount}
                >
                    {TEXT_create_account}
                </RoundedButton>
            </MInfoAreaForm>
        </Layout>
    )
}

export default CreateAccountForm;