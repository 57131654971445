import { PopupBackdropContainer } from "../style";

const PopupBackdrop = ({ isDisplay, zIndex }) => {
    return (
        <PopupBackdropContainer
            isDisplay={isDisplay}
            zIndex={zIndex}
        />
    );
};

export default PopupBackdrop;