import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../Requests/auth";

const createAccountSlice = createSlice({
  name: "createAccount",
  initialState: {
    response: [],
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(registerUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const authCreateAccountReducer = createAccountSlice.reducer;